import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-phone-input-2/lib/style.css';
import './assets/styles/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';

enum AppName {
  TESTEDME = 'testedme',
  TRUSTEDME = 'trustedme',
  HEALTHCHECK = 'healthcheck',
}

const appLoaders = {
  [AppName.TESTEDME]: () =>
    import(/* webpackMode: "lazy" */ `./appVariants/TestedMe`),
  [AppName.TRUSTEDME]: () =>
    import(/* webpackMode: "lazy" */ `./appVariants/TrustedMe`),
  [AppName.HEALTHCHECK]: () =>
    import(/* webpackMode: "lazy" */ `./appVariants/HealthCheck`),
};

const getUsedApp = (): AppName => {
  if (window.location.host === 'localhost:3000') {
    return AppName.TESTEDME;
  }
  if (window.location.host === 'localhost:3001') {
    return AppName.HEALTHCHECK;
  }
  if (window.location.host === 'localhost:3002') {
    return AppName.TRUSTEDME;
  }

  return (process.env.REACT_APP_USED_APP_NAME as AppName) || AppName.TESTEDME;
};

const usedApp: AppName = getUsedApp();

const loadApp = appLoaders[usedApp];

loadApp().then(({ default: AppRoot }) => {
  const rootElement = document.getElementById('root');
  const renderMethod =
    rootElement && rootElement.hasChildNodes()
      ? ReactDOM.hydrate
      : ReactDOM.render;

  renderMethod(
    <React.StrictMode>
      <AppRoot />
    </React.StrictMode>,
    rootElement
  );
});
